.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mySwiper{
  max-width: 90vw;
}

.swiper-slide img{
  width: 100%;
}

.gradDiv{
  background-image: url(list94.png);
  background-size: cover;
  background-position: center;
}

.gradDiv img{
  opacity: 0;
}

.CF{
  color: #000;
  font-size: 70px;
  position: absolute;
  bottom: 120px;
  left: 50%;
}

.CF input{
  max-width: 140px;
  text-align: right;
  border: none;
  background: transparent;
  font-size: 70px;
}